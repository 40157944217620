import React, { useEffect, useState } from 'react';
import { STRING_CONFIG, SETTING_CONFIG } from 'utils';

function scaleAttribute(value, scaleFactor = 1, unitSymbol = "") {
  if (typeof value === "number") {
    return `${(value * scaleFactor).toFixed(2)}${unitSymbol ? "" + unitSymbol : ""}`;
  } else if (typeof value === "object" && value !== null) {
    return Object.values(value)
      .map(val => (typeof val === "number" ? `${(val * scaleFactor).toFixed(2)}${unitSymbol ? "" + unitSymbol : ""}` : val))
      .filter(val => val !== "")
      .join(", ");
  }
  return value;
}

export function SimplifyAttributes(attributes, categoryId) {
  const parsedAttributes = typeof attributes === "string" ? JSON.parse(attributes) : attributes;

  function processAttributes(obj, categoryId) {
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        try {
          const parsedValue = JSON.parse(obj[key]);
          if (parsedValue && typeof parsedValue === "object") {
            obj[key] = parsedValue;
          }
        } catch (e) {
        }
      }
      if (obj[key] && typeof obj[key] === "object") {
        if ("value" in obj[key]) {
          const { value, "phy-qty-id": phyQtyId } = obj[key];
          if (value === "" || value === null || value === undefined) {
            delete obj[key];
            return;
          }
          let scaleFactor = 1, unitSymbol = "";
          if (phyQtyId && SETTING_CONFIG.unitConversion[categoryId][phyQtyId]) {
            scaleFactor = SETTING_CONFIG.unitConversion[categoryId][phyQtyId]["scale-factor"];
            unitSymbol = SETTING_CONFIG.unitConversion[categoryId][phyQtyId]["unit-symbol"];
          }
          obj[key] = scaleAttribute(value, scaleFactor, unitSymbol);
        } else {
          processAttributes(obj[key], categoryId);
        }
      } else if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    }
  }
  processAttributes(parsedAttributes, categoryId);
  return parsedAttributes;
}

export const transformAttribute = (attributeString) => {
  const parts = attributeString.split("_");
  
  if (parts.length === 2 && parts[0] === "attribute") {
    const number = parts[1];
    return `attr${number}`;
  }
  throw new Error("Invalid: Attribute format. Expected: 'attribute_<number>'.");
};

export const camelize = (str) => str
  .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
  .replace(/\s+/g, '');

export const notSpecialCharacter = (string) => /[^a-zA-Z0-9]+/g.test(string);

export function getTextForValue(value) {
  if (value >= 0 && value < 2) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.one}`;
  } if (value >= 2 && value < 3) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.two}`;
  } if (value >= 3 && value < 4) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.three}`;
  } if (value >= 4 && value < 5) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.four}`;
  } if (value === 5) {
    return `${STRING_CONFIG.entityCaption.review.ratingSegment.five}`;
  }
  return 'unknown';
}

export const truncateFullName = (fullName) => {
  if (fullName.length <= 16) {
    return fullName;
  }
  return `${fullName.slice(0, 12)}...`;
};

export function formatNumber(num) {
  if (num < 100) {
    return num.toString();
  } if (num < 1000) {
    return '100+';
  } if (num < 1000000) {
    return `${(num / 1000).toFixed(0)}K+`;
  }
  return '999K+';
}

export function formatDate(dateString) {
  const options = {
    year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
  };
  const date = new Date(dateString);
  return date.toLocaleString('en-IN', options);
}

export function getAdsDurationString(amount) {
  const daysMapping = {
    5: '1 day',
    25: '7 days',
    65: '30 days',
    450: '1 year',
    999: 'forever',
  };
  return `${daysMapping[amount] || 'unknown duration'}`;
}

export default function useClickOutside(ref, fun) {
  useEffect(() => {
    const listener = (e) => {
      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }
      fun();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref]);
}

export function useMedia(query) {
  const [matches, setMatches] = useState(() => window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    const updateMatches = (event) => {
      setMatches(event.matches);
    };

    updateMatches(mediaQuery);

    const mediaQueryEventListener = (event) => updateMatches(event);
    mediaQuery.addEventListener('change', mediaQueryEventListener);

    return () => {
      mediaQuery.removeEventListener('change', mediaQueryEventListener);
    };
  }, [query]);

  return matches;
}

export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const onPopState = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, []);

  return null;
};

export const useModal = () => {
  const openModalButtonRef = React.useRef(null);
  const [isModalShown, setIsModalShown] = React.useState(false);

  const showModal = () => {
    setIsModalShown(true);
  };

  const hideModal = () => {
    setIsModalShown(false);
    openModalButtonRef.current.focus();
  };

  return {
    openModalButtonRef,
    isModalShown,
    showModal,
    hideModal,
  };
};
