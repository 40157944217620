import React from 'react';
import './style.css';
import Text from 'ReusableComponents/Text';
import Box from 'ReusableComponents/Box';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { H3 } from 'ReusableComponents/Headings';
import { formatNumber, useMedia } from 'helpers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DESIGN_CONFIG } from 'utils';

function ProductCard({ productData }) {
  const history = useHistory();
  const MQ500 = useMedia('(max-width:500px)');
  const MQ450 = useMedia('(max-width:450px)');
  return (
    <li className="product-card-list-item" tabIndex={0} onClick={() => history.push(`/product/${productData.product_id}`)}>
      <div className="product-card-list-item-image">
        <LazyLoadImage
          alt=""
          effect="blur"
          height="100%"
          src={productData.product_image_url}
          style={{ objectFit: 'contain' }}
          width="100%"
        />
      </div>
      <div className="product-card-list-item-details">
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Box
            alignItems="center"
            borderRadius="2px"
            display="flex"
            gap=".25rem"
            h={MQ500 ? '1.5rem' : '2rem'}
            overflow="hidden"
          >
            <img
              alt=""
              height="100%"
              src={productData.brand_image_url}
              style={{ objectFit: 'contain' }}
              width={MQ500 ? '24' : '32'}
            />
            <Text fontSize="0.813rem" fontWeight={400} margin="0" textColor={DESIGN_CONFIG.color.text.text100}>{productData.brand}</Text>
          </Box>
          <Box
            alignItems="center"
            background={DESIGN_CONFIG.color.primary.primary50}
            borderRadius="0.25rem"
            display="flex"
            gap="0.156rem"
            padding="0.25rem 0.313rem"
          >
            <img src="/icons/homePageStar.svg" alt="" height={12} width={12} />
            <Text
              fontSize={DESIGN_CONFIG.typography.common.webTag.fontSize}
              fontWeight={DESIGN_CONFIG.typography.common.webTag.fontWeight}
              margin="0"
              textColor={DESIGN_CONFIG.color.primary.primaryMain}
            >
              {productData.product_value}
            </Text>
          </Box>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          overflow="hidden"
          width="100%"
        >
          <H3
            className="single-line-ellipsis"
            color={DESIGN_CONFIG.color.text.text500}
            fontSize={DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}
            fontWeight={DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontWeight}
            lineHeight="1.125rem"
            margin=".5rem 0"
          >
            {productData.variant}
          </H3>
        </Box>
        <Box alignItems="center" display="flex" gap=".5rem" marginBottom=".5rem">
          <LazyLoadImage
            alt=""
            effect="blur"
            height="100%"
            src={MQ450 ? '/assets/userGroupProductCardSmall.svg' : '/assets/userGroupProductCard.svg'}
            style={{ objectFit: 'contain' }}
          />
          <Text
            fontSize={MQ450 ? '.75rem' : `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontSize}`}
            fontWeight={DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontWeight}
            margin="0"
            textColor={DESIGN_CONFIG.color.text.text200}
          >
            {formatNumber(productData.total_reviews)}
            {' '}
            reviews
          </Text>
        </Box>
      </div>
    </li>
  );
}

export default ProductCard;
